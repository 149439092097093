import React, { useState, useEffect, useRef } from "react";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  DatePicker,
  Calendar,
} from "@material-ui/pickers";
import Slider from "react-slick";
import {
  Grid,
  RadioGroup,
  Radio,
  FormControlLabel,
  Paper,
  Typography,
  Button,
  // Button
} from "@material-ui/core";

import Cart from "./Cart";
import Loader from "./Loader";
import { last } from "lodash";

import api from "../api";

const getBusinessHour = (cid) => {
  return api.get("/center/:cid/business-hour", { params: { cid } });
};

const dayShort = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"];
const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const monthShort = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 12,
  slidesToScroll: 6,
  autoplay: false,
  className: "time-slider",
  responsive: [
    {
      breakpoint: 2000,
      settings: {
        slidesToShow: 10,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 980,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        // variableWidth: true,
      },
    },
    {
      breakpoint: 599,
      settings: {
        arrows: false,
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 359,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        // variableWidth: true,
      },
    },
  ],
};

const BookingStep3 = React.memo((props) => {
  const datesSlider = useRef(null);

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [availableTime, setAvailableTime] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorCode, setErrorCode] = useState(false);
  const [nonWorkingDays, setNonWorkingDays] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [showCalender, setShowCalender] = useState(false);
  const sid = props.cart.item.map((i) => i.id);

  useEffect(() => {
    getBusinessHour(props.center.id)
      .then((res) => {
        const { data = [] } = res;
        setNonWorkingDays(
          data.filter((i) => !i.is_on).map((i) => i.day_number)
        );
      })
      .catch((err) => {
        setNonWorkingDays([]);
      });
    let date;
    if (props.cart.date) {
      date = props.cart.date;
    } else {
      date = new Date();
      if (!props.center.is_book_today) {
        date.setDate(date.getDate() + 1);
      }
    }
    setSelectedDate(date);
    getAvailableTime(date);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectTimeSlot = (index) => {
    const selectedTime = availableTime[index] || null;
    if (selectedTime) {
      selectedDate.setHours(selectedTime.h_24);
      selectedDate.setMinutes(selectedTime.m);
    }
    setSelectedIndex(index);
    props.handleSelectTimeSlot(selectedDate);
  };

  const onDateChange = (date) => {
    if (date) {
      // Calculate the number of difference
      const diffTime = Math.abs(date - selectedDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      setSelectedIndex(null);
      setAvailableTime([]);
      setSelectedDate(date);
      getAvailableTime(date);

      datesSlider.current.slickGoTo(diffDays);
    }
  };

  const getAvailableTime = (dt = props.cart.date) => {
    const eid =
      props.isRescheduleMode && props?.cart?.employee?.username === "Anyone"
        ? 0
        : props.cart?.employee?.id || 0;
    const date = `${dt.getFullYear()}${("0" + (dt.getMonth() + 1)).slice(-2)}${(
      "0" + dt.getDate()
    ).slice(-2)}`;
    const cid = props.center.id;
    const booking_id = props.booking.booking_id;
    setIsLoading(true);
    setErrorCode(false);
      const d = new Date();
      const utc_offset = d.getTimezoneOffset() * -1;
    api
      .get("/employee/:eid/availability", {
        params: { eid, date, sid, cid, booking_id, utc_offset },
      })
      .then((res) => {
        const { data = [] } = res;
        if (data?.message) {
          setErrorCode(data?.message);
          setIsLoading(false);
          return;
        }
        const today = new Date();
        let timeSlots = [];
        let lastAvlTime = new Date();
        const isDoubleBooking = props.center.setting.double_booking;
        const advanceBookingTime = props.center.setting.advance_booking_time;
        if (
          dt.getDate() === today.getDate() &&
          dt.getMonth() === today.getMonth()
        ) {
          data.forEach((i) => {
            const plus2Hr = new Date();
            const addStartTime = isDoubleBooking
              ? 15 * 60 * 1000
              : (advanceBookingTime || 0) * 60 * 1000;
            plus2Hr.setTime(plus2Hr.getTime() + addStartTime);
            const avlTime = new Date();
            avlTime.setHours(i.h_24, i.m);

            if (plus2Hr <= avlTime) {
              timeSlots.push(i);
            }
          });
        } else {
          timeSlots = data;
        }

        !timeSlots.length && setErrorCode("unavialable");
        setAvailableTime(timeSlots);
        setIsLoading(false);
        const si = timeSlots.findIndex(
          (i) =>
            i.h_24 === ("0" + dt.getHours()).slice(-2) &&
            i.m === ("0" + dt.getMinutes()).slice(-2)
        );
        setSelectedIndex(si >= 0 ? si : null);
      })
      .catch((e) => {
        setAvailableTime([]);
        setIsLoading(false);
        setErrorCode(e.response.data.response.data.code);
      });
  };

  const isDateDisabled = (date) => nonWorkingDays.includes(date.getDay() + 1);

  const minDate = new Date();
  const maxDate = new Date();
  if (!props.center.is_book_today) {
    minDate.setDate(minDate.getDate() + 1);
  }
  maxDate.setMonth(maxDate.getMonth() + 6);
  const loopDate = new Date();
  if (!props.center.is_book_today) {
    loopDate.setDate(loopDate.getDate() + 1);
  }
  const dateItem = () => {
    const items = [];
    while (loopDate <= maxDate) {
      if (!isDateDisabled(loopDate)) {
        const isActive =
          loopDate.getMonth() === selectedDate.getMonth() &&
          loopDate.getDate() === selectedDate.getDate();
        const date = new Date(loopDate);
        items.push(
          <a
            key={loopDate.getTime()}
            href="/#"
            className={isActive ? "active" : ""}
            onClick={(e) => {
              e.preventDefault();
              onDateChange(date);
            }}
          >
            <span>{dayShort[loopDate.getDay()]}</span>
            {loopDate.getDate()} {monthShort[loopDate.getMonth()]}
          </a>
        );
      }
      loopDate.setDate(loopDate.getDate() + 1);
    }
    return items;
  };

  return (
    <>
      <div className="hide-in-xs">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <a
            href="/#"
            className="calIcon"
            onClick={(e) => {
              e.preventDefault();
              setShowCalender(true);
            }}
          >
            <svg
              className="contras-icon"
              height="24px"
              viewBox="0 0 24 24"
              width="24px"
              fill="#000000"
            >
              <path d="M9 11H7v2h2v-2zm4 0h-2v2h2v-2zm4 0h-2v2h2v-2zm2-7h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11z" />
            </svg>
          </a>
          <DatePicker
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            maxDate={maxDate}
            minDate={minDate}
            value={selectedDate}
            onChange={onDateChange}
            open={showCalender}
            shouldDisableDate={isDateDisabled}
            autoOk
            onAccept={() => setShowCalender(false)}
            initialFocusedDate={selectedDate}
            InputProps={{
              disableUnderline: true,
            }}
          />
        </MuiPickersUtilsProvider>
        {selectedDate && (
          <Slider ref={datesSlider} {...settings}>
            {dateItem()}
          </Slider>
        )}
      </div>
      <div className="display-in-xs datepicker-full-width">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Paper style={{ overflow: "hidden" }}>
            <Calendar
              date={selectedDate}
              onChange={onDateChange}
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              maxDate={maxDate}
              minDate={minDate}
            />
          </Paper>
        </MuiPickersUtilsProvider>
        {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              className="datepicker-full-width"
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              maxDate={maxDate}
              minDate={minDate}
              value={selectedDate}
              onChange={onDateChange}
              open={true}
              shouldDisableDate={isDateDisabled}
              autoOk
              onAccept={() => setShowCalender(false)}
              initialFocusedDate={selectedDate}
              InputProps={{
                disableUnderline: true,
              }}
            />
          </MuiPickersUtilsProvider> */}
      </div>

      <Grid container spacing={8} className="popup-container">
        <Grid item xs={12} sm={12} md={7}>
          <div className="list-of-radio-wrapp time-popup card">
            {selectedDate && (
              <h3 className="p-2">{`${
                months[selectedDate.getMonth()]
              } ${selectedDate.getDate()}, ${days[selectedDate.getDay()]}`}</h3>
            )}
            {!!availableTime.length && (
              <RadioGroup aria-label="gender" name="customized-radios">
                <ul className="list-of-radio list-of-time">
                  {availableTime.map((i, index) => (
                    <li
                      key={index}
                      className={index === selectedIndex ? "active" : ""}
                    >
                      {" "}
                      <Button
                        className="time-selection-btn"
                        onClick={() => handleSelectTimeSlot(index)}
                        color="inherit"
                        variant={
                          index === selectedIndex ? "contained" : "outlined"
                        }
                      >
                        {`${Number(i.h_12)}${
                          Number(i.m) ? ":" + Number(i.m) : ""
                        } ${i.period}`}
                      </Button>
                      {/*  <FormControlLabel
                          value={index}
                          className={index === selectedIndex ? "active" : ""}
                          control={
                            <Radio
                              value={index}
                              name="time"
                              onChange={() => handleSelectTimeSlot(index)}
                              checked={index === selectedIndex}
                            />
                          }
                          label={`${i.h_12}:${i.m} ${i.period.toLowerCase()}`}
                        />{" "} */}
                    </li>
                  ))}
                </ul>
              </RadioGroup>
            )}
            {isLoading && <Loader />}
            {!isLoading && !selectedDate && (
              <div className="error-time text-center ">
                <h3>Select date for available time</h3>
              </div>
            )}
            {(!isLoading && errorCode) && (
              <>
                {errorCode === "unavialable" ? (
                  <div className="error-time text-center errTimeMsg p-2">
                    <p>
                      Sorry! {props?.cart?.employee?.username === "anyone" ? "They don't" : props?.cart?.employee?.username + " doesn't "} have any appointments available on this
                      day, please call{" "}
                      <a href="tel: {props.center.tel}">{props.center.tel}</a>{" "}
                      to see if there are any last minute openings, or try
                      another date.
                    </p>
                  </div>
                ) : (
                  <div className="error-time text-center errTimeMsg">
                    <p>{errorCode} </p>
                  </div>
                )}
              </>
            )}
          </div>
        </Grid>

        <Grid item xs={12} sm={12} md={5} className="hide-in-mob">
          <Cart center={props.center} cart={props.cart}></Cart>
        </Grid>
      </Grid>
    </>
  );
});

export default BookingStep3;
